import './AboutMe.css'

export default function AboutMe () {
    return (
        <div className='aboutMe'>
            <h2>About Me</h2>
            <p>My name is Micah Eckard. I am a developer based out of Richmond, Virginia.
                I am trained in HTML, CSS, JavaScript, React, and Redux. My curious nature,
                love of puzzles, and inate creativity make me a passionate and relentless problem solver.
                I have Bachelor's degree in Theology and am a professionally certified Developer by Codecademy.
            </p>
            <p>
                When I am not coding, I am spending time with my beautiful wife and our cat,
                watching Survivor, organizing my Pokemon card collection, or reading something new!
            </p>
        </div>
    )
}