import PictureOfMe from '../../Resources/SarahMattozziPhotography-MicahAlanna-Engaged-162.jpg'
import './Home.css'

export default function Home () {
    return (
        <div className='home'>
            <div className='banner'>
                <div className='banner-text'>
                    <h1>Micah Eckard</h1>
                    <h2>RVA-based Developer</h2>
                </div>
                <img src={PictureOfMe} alt='It is me!'></img>
            </div>
        </div>
    )
}