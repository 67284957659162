import tanAndHandsome from '../../Resources/Images/attachment-tan-and-handsome.jpeg'
import mothership from '../../Resources/Images/MothershipConnection.jpg'
import './MusicPage.css'
import terminal from '../../Resources/Images/Terminal.jfif'

export default function MusicPage () {

    const music = [
        {
            art: tanAndHandsome,
            album: 'Tan and Handsome EP',
            artist: 'Tan and Handsome',
            genre: 'Metalcore',
        },
        {
            art: mothership,
            album: 'Mothership Connection',
            artist: 'Parliament',
            genre: 'Funk'
        },
        {
            art: terminal,
            album: 'Terminal',
            artist: 'The Burden',
            genre: 'Post-Hardcore'
        }
    ]

    return (
        <div className='musicPage'>
            <h1>What I'm Listening To</h1>
            <div className='musicCard'>
                {music.map( music => {
                    return (
                    <div className='music'>
                        <img src={music.art} alt='A surrealist portrait of a man in a bowler hat'></img>
                        <ul>
                            <li><b>Artist:</b> {music.artist}</li>
                            <li><b>Album:</b> {music.album}</li>
                            <li><b>Genre:</b> {music.genre}</li>
                        </ul>
                    </div>
                )})}
            </div>
        </div>
    )
}