import './Navbar.css'
import { Link } from 'react-router-dom'

export default function NavBar () {

return(
<div className="navbar">
    <svg width="218" height="125" viewBox="0 0 218 125" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M84.1423 84L67.5663 49.504V40.352H70.3183L88.2383 77.792H86.8943L104.43 40.352H105.134V48.8L88.5583 84H84.1423ZM57.1343 84V80.736H63.3423V43.616H57.2623V40.352H67.5663V80.736H74.2863V84H57.1343ZM98.3503 84V80.736H105.134V40.352H117.742V43.616H111.662V80.736H117.87V84H98.3503ZM122.072 84V80.736H128.28V43.616H122.2V40.352H135V84H122.072ZM135 84V80.288H162.2V84H135ZM146.136 68.192C146.136 66.3573 145.794 65.056 145.112 64.288C144.429 63.4773 143.213 63.072 141.464 63.072H149.656V73.632H146.136V68.192ZM135 63.072V59.424H149.656V63.072H135ZM153.496 80.288C155.373 80.288 156.674 79.8827 157.4 79.072C158.125 78.2613 158.488 76.8747 158.488 74.912V68.128H162.2V80.288H153.496ZM141.464 59.424C143.213 59.424 144.429 59.04 145.112 58.272C145.794 57.504 146.136 56.2027 146.136 54.368V50.528H149.656V59.424H141.464ZM135 44V40.352H161.752V44H135ZM157.976 49.44C157.976 47.4347 157.592 46.0267 156.824 45.216C156.098 44.4053 154.797 44 152.92 44H161.752V55.648H157.976V49.44Z" fill="#043050"/>
        <circle opacity="0.3" cx="111.5" cy="62.5" r="62.5" fill="#043050"/>
    </svg>
    <nav>
      <ul>
      <Link to='/'><li>Home</li></Link>
        <Link to='/about-me'><li>About Me</li></Link>
        <Link to='/projects'><li>Projects</li></Link>
        <Link to='/listening-to'><li>What I'm listening to</li></Link>
      </ul>
    </nav>
</div>
)
}