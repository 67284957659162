import React from "react";
import './Projects.css'


export default function Projects () {
    const projects = [
        {
            name: 'Reddit Client',
            tech: 'React, Redux, Reddit API',
            link: 'https://github.com/meckard/Reddit-Project'
        },
        {
            name: 'GraphQL Blogging App',
            tech: 'GraphQL, Prisma, Javascript, Jest',
            link: 'https://github.com/meckard/Graphql-Course'
        },
        {
            name: 'Pokedex App',
            tech: 'React, Redux, Pokemon API',
            link: 'https://github.com/meckard/Pokedex'
        }
    ]

    return (
        <div className='projects'>
            <h1>Projects</h1>
           <div className='projectsList'>
            {projects.map(project => {
                return (
                <div className='project'>
                    <h2>{project.name}</h2>
                    <ul>
                        <li><b>Tech used:</b> {project.tech}</li>
                        <li><b>Link:</b> <a target='_blank' rel='noreferrer' href={project.link}>{project.link}</a></li>
                    </ul>
                </div>
                )
            })}
            </div>
        </div>
    )
}