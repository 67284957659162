import './App.css';
import NavBar from './Components/Navbar/Navbar';
import { BrowserRouter as Router, Route} from 'react-router-dom'
import Home from './Components/Home/Home';
import AboutMe from './Components/AboutMe/AboutMe';
import Projects from './Components/Projects/Projects';
import MusicPage from './Components/MusicPage/MusicPage';

function App() {
  return (
    <Router>
      <div className='app'>
        <NavBar />
        <Route exact path='/'>
          <Home/>
        </Route>
        <Route path='/about-me'>
          <AboutMe/>
        </Route>
        <Route path='/projects'>
          <Projects/>
        </Route>
        <Route path='/listening-to'>
          <MusicPage/>
        </Route>
        <Route path='/*'/>
      </div>
    </Router>
  );
}

export default App;
